import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const SecuredvsUnsecuredLoans = () => {
  const data = useStaticQuery(graphql`
    query TaxRefund {
      contentfulComponentJsonTable(tableName: {eq: "TaxRefund"}) {
        data {
          td1 {
            content
          }
          td2 {
            content
          }
        }
      }
    }
  `)
  
  const items = data.contentfulComponentJsonTable.data

  return (
    <Table>
      <thead>
        <tr>
          <th>Income Tax Brackets</th>
          <th className="text-right">Tax You'll Pay</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) => 
          <tr key={i}>
            <td>{item.td1.content}</td>
            <td className="text-right">{item.td2.content}</td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: left;
  font-size: 18px;
  th {
    font-family: QuicksandBold;
    background-color: rgba(210, 242, 226, 0.8);
  }
  th,
  td {
    width: 50%;
    padding: 13px 24px;
    border: 1px solid #d4dbe0;
  }
  border: 1px solid #d4dbe0;
  .text-right {
    text-align: right;
  }
  @media screen and (max-width: 768px) {
    font-size: 13px;
    th,
    td {
      padding: 12px;
    }
  }
`

export default SecuredvsUnsecuredLoans
