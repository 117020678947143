import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { MdKeyboardArrowRight } from 'react-icons/md'
import { BiTimeFive } from 'react-icons/bi'

const GuideHero = ({ data }) => {
  const { heading, pageTitle, subheading, additionalText, heroImage } = data

  return (
    <div style={{ background: 'linear-gradient(45deg, #172d3d, #2c5571)' }}>
      <GuideHeroWrapper>
        <section>
          <p className="flex-row">
            <Link to="/guides">Guides</Link>
            <MdKeyboardArrowRight />
            <span><strong>{heading.childMarkdownRemark.headings[0].value}</strong></span>
          </p>
          <div className="guide-info">
            <div
              dangerouslySetInnerHTML={{
                __html: pageTitle.childMarkdownRemark.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: subheading?.childMarkdownRemark.html,
              }}
            />
          </div>
          <p className="flex-row readtime">
            <BiTimeFive />
            {additionalText ? additionalText : null}
          </p>
        </section>
        <section className="imgWrapper">
          <GatsbyImage
            image={heroImage?.gatsbyImageData}
            alt={heroImage.title}
          />
        </section>
      </GuideHeroWrapper>
    </div>
  )
}

const GuideHeroWrapper = styled.div`
  padding: 0 30px;
  margin: 0 auto;
  max-width: 1231px;
  color: var(--white);
  display: flex;
  flex-direction: row;
  position: relative;
  section {
    flex: 1;
  }
  .flex-row {
    align-items: center;
    font-size: 14px;
    svg {
      margin: 0 3px;
    }
  }
  .guide-info {
    margin: 60px 30px 60px 0;
  }
  .readtime {
    position: absolute;
    bottom: 0;
  }
  .imgWrapper {
    margin-right: -30px;
    display: flex;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .imgWrapper {
      display: none;
    }
    img {
      height: 0;
    }
  }
  a {
    color: var(--white) !important;
    text-decoration: underline;
  }
  @media screen and (max-width: 479px) {
    h1 {
      font-size: 32px;
    }
    .guide-info {
      margin: 0 0 45px;
    }
  }
`

export default GuideHero
