import React from 'react'
import styled from 'styled-components'

const TableOfContents = ({ contents }) => {
  return (
    <Wrapper>
      <UpdateAndShare>
        {contents.updatedDate && (
          <div
            dangerouslySetInnerHTML={{
              __html: contents.updatedDate.childMarkdownRemark.html,
            }}
          />
        )}
        <Share>
          <div>SHARE</div>
          <div className="addthis_inline_share_toolbox">           
          </div>
        </Share>
      </UpdateAndShare>
      <div
        className="contentLink"
        dangerouslySetInnerHTML={{
          __html: contents.links.childMarkdownRemark.html,
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: start;
    > * {
      margin: 20px 0;
    }
	}
  p {
    font-size: 16px;
    margin: 0;
  }
  .contentLink {
    max-width: 750px;
    a {
      font-family: QuicksandSemiBold;
      margin: 0 15px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    p {
      margin-top: 20px;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      line-height: 3rem;
      padding: 20px;
      background-color: rgba(244, 245, 246, 0.7);
      border-radius: 10px;
    }
  }
`
const Share = styled.div`
  width: 200px;
  margin-right: 40px;
  position: sticky;
  top: 90px;
  div {
    width: 200px;
    font-size: 15px;
    margin-top: 10px;
    color: #79838d;
    font-family: QuicksandBold;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    top: 0px;

	};

`
const UpdateAndShare = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

`

export default TableOfContents
