import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ScrollIndicator = ({ background, activeBarColor }) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    if (typeof document !== "undefined") {
      const updatePosition = () => {
        setScrollPosition(window.scrollY)
      }
      window.addEventListener('scroll', updatePosition)
      updatePosition()
      return () => window.removeEventListener('scroll', updatePosition)
    } else {
      return
    }
  }, [])

  const progress = () => {
    if (typeof document !== "undefined") { 
      const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
      
      const scrolled = (scrollPosition / height) * 100
      return `${scrolled}%`
    } else {
      return
    }
  }

  return (
    <Bar background={background} activeBarColor={activeBarColor}>
      <div className="progress-bar" style={{width : progress()}} />
    </Bar>
  )
}


const Bar = styled.div`
  position: -webkit-sticky;
  position: sticky;
  overflow: hidden;
  top: 70px;
  width: auto;
  height: 8px;
  z-index: 1;
  background: ${props => props.background || "var(--base-gray)"};
  .progress-bar {
    height: 8px;
    background: ${props => props.activeBarColor || "var(--base-green)"};
  }
  margin: 0;
  @media screen and (max-width: 991px) {
    top: 64px;
  }
`

export default ScrollIndicator
