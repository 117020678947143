import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import { GatsbyImage } from 'gatsby-plugin-image'

import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import BulletListItem from '../UI/BulletListItem'
import HomeButton from '../UI/Buttons/HomeButton'
import TableOfContents from './TableOfContents'
import MapTable from '../UI/Tables/MapTable'
import FaqsAccordion from '../UI/FaqsAccordion'

const GuideSection = ({ pageSection, themeColor }) => {

  const isType = (content) => {
    return content && content.internal && content.internal.type
  }
  
  const sections = []
  const rootContentType = pageSection.internal.type

  const renderSection = () => {

    if (rootContentType.includes('ContentfulComponentGuidesPageSection')) {
      const childContents = pageSection.contents

      childContents.map((childContent, i) => {
        if (isType(childContent)) {

          if (childContent.internal.type.includes('ContentfulComponentGuidesText')) {
            sections.push(
              <div
                key={`guides-text-${i}`}
                className="guideText"
                style={{textAlign: pageSection.alignContentToCenter ? "center" : "left"}}
                dangerouslySetInnerHTML={{
                  __html: childContent.text.childMarkdownRemark.html,
                }}
              />
            )
          } 

          else if (childContent.internal.type.includes('ContentfulComponentGuidesBulletListItem')) {
            sections.push(<BulletListItem key={`guides-bullet-${i}`} item={childContent} />)
          } 

          else if (childContent.internal.type.includes('ContentfulComponentGuidesButton')) {
            sections.push(
              <div key={`guides-button-${i}`} className="btnWrapper">
                <HomeButton
                  height={BUTTON_SIZE.HEIGHT_S}
                  padding={BUTTON_SIZE.PADDING_S}
                  fontSize={BUTTON_SIZE.FONT_SIZE_S}
                  background={themeColor}
                  color={BUTTON_COLOR.TEXT_COLOR_WHITE}
                  text={childContent.buttonText}
                  margin={pageSection.alignContentToCenter ? "0 auto" : "0"}
                  handleClick={() => navigate(childContent.slug)}
                />
              </div>
            )
          } 

          else if (childContent.internal.type.includes('ContentfulComponentGuidesImage')) {
            sections.push(
              <GatsbyImage
                key={`guides-image-${i}`}
                className="imgWrapper"
                style={{maxWidth: childContent.imageMaxWidth}}
                image={childContent.image?.gatsbyImageData}
                alt={childContent.image.title}
              />
            )
          } 

          else if (childContent.internal.type.includes('ContentfulComponentTable')) {
            sections.push(
              <MapTable key={`guides-table-${i}`} CMSTableOptions={childContent.tableOptions} />
            )
          }

          else if (childContent.internal.type.includes('ContentfulComponentGuideFaQs')) {
            sections.push(
              <FaqsAccordion key={`faqs-accordion-${i}`} QandAs={childContent.faQs} />
            )
          }

          else {
            console.error(`Rendering ${childContent.internal.type} failed.`)
          }
        }
        return null
      })
    } 

    else if (rootContentType.includes('ContentfulComponentGuidesTableofContent')) {
      sections.push(<TableOfContents contents={pageSection} key={pageSection.links} />)
    } 

    else {
      console.error(
        `Root content type has to be ContentfulComponentGuidesPageSection or ContentfulComponentGuidesTableofContent but it's ${rootContentType}`
      )
    }

    return sections
  }

  return <GuideSectionWrapper>{renderSection()}</GuideSectionWrapper>
}

const GuideSectionWrapper = styled.div`
  .guideText {
    h3 {
      margin-top: 40px;
    }
    a {
      text-decoration: underline;
      font-family: QuicksandBold;
    }
  }
  .imgWrapper {
    border-radius: 20px;
    display: block;
    margin: 50px auto;
  }
  .btnWrapper {
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    button:hover {
      filter: brightness(85%);
      color: var(--white);
    }
  }
  a {
    &:hover {
      color: inherit;
    }
  }
`

export default GuideSection
