import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import Helmet from "react-helmet"

import Seo from '../components/seo'
import Layout from '../layouts/layout'
import ScrollIndicator from '../components/UI/ScrollIndicator'
import GuideHero from '../components/ForGuides/GuideHero'
import GuideSection from '../components/ForGuides/GuideSection'
import { getArticleMarkupForGuide } from '../support/structuredDataConfig/guide'

const GuideTemplate = ({ data, path }) => {
  const {
    themeColorCode,
    contents,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaImage
  } = data.contentfulPageGuide

  const getIdForSection = (content) => {
    if (
      content.internal.type.includes('ContentfulComponentGuidesPageSection')
    ) {
      return content.idForTableOfContents || null
    }
  }

  const backImageExists = (content) => {
    return (
      content.internal.type.includes('ContentfulComponentGuidesPageSection') &&
      content.backgroundImage
    )
  }
  const mmeStructuredData = getArticleMarkupForGuide(data.contentfulPageGuide)

  return (
    <Layout>
      <Seo 
        title={seoMetaTitle} 
        description={seoMetaDescription} 
        image={seoMetaImage?.fluid?.src} 
        path={path} 
        structuredData={mmeStructuredData}
      />
      <Helmet>
        <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6036e2612737a33a"/>
      </Helmet>
      <GuideHero data={data.contentfulPageGuide} />
      <ScrollIndicator activeBarColor={themeColorCode} />

      {/* Render child contents */}
      <PageSections>
        {contents?.map((content, i) =>
          backImageExists(content) ? (
            <div id={getIdForSection(content)} key={`page-section-${i}`} className="section">
              <div className="bgImgWrapper">
                <BgImage
                  className="bgImage"
                  image={getImage(content.backgroundImage?.gatsbyImageData)}
                >
                  <GuideSection
                    pageSection={content}
                    themeColor={themeColorCode}
                  />
                </BgImage>
              </div>
            </div>
          ) : (
            <div id={getIdForSection(content)} key={`page-section-${i}`} className="section">
              <GuideSection pageSection={content} themeColor={themeColorCode} />
            </div>
          )
        )}
      </PageSections>
    </Layout>
  )
}

const PageSections = styled.div`
  h1, h2, h4, h5 {
    margin: 18px 0;
  }
  h3 {
    margin-bottom: 10px;
  }
  h4 {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.5px;
  }
  .section {
    margin: 0 auto;
    padding: 56px 30px;
    max-width: 1020px;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      max-width: 100vw;
      padding: 40px 24px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100vw;
      padding: 40px 20px;
    }
  }
  .bgImgWrapper {
    margin: 0 auto;
    max-width: 1020px;
    border-radius: 10px;
    overflow: hidden;
    .bgImage {
      padding: 24px;
    }
  }
  a {
    text-decoration: underline;
  }
`

export default GuideTemplate

export const pageQuery = graphql`
  query Guide($slug: String!) {
    contentfulPageGuide(slug: { eq: $slug }) {
      themeColorCode
      heading {
        childMarkdownRemark {
          html
          headings {
            value
          }
        }
      }
      createdAt
      pageTitle {
        childMarkdownRemark {
          html
          headings {
            value
          }
        }
      }
      subheading {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(width: 720)
        title
      }
      additionalText
      contents {
        ... on ContentfulComponentGuidesTableofContent {
          internal {
            type
          }
          updatedDate {
            childMarkdownRemark {
              html
            }
          }
          links {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulComponentGuidesPageSection {
          internal {
            type
          }
          idForTableOfContents
          alignContentToCenter
          backgroundImage {
            gatsbyImageData(width: 1000)
            title
          }
          contents {
            ... on ContentfulComponentGuidesText {
              internal {
                type
              }
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulComponentGuidesBulletListItem {
              internal {
                type
              }
              expand
              heading {
                childMarkdownRemark {
                  html
                }
              }
              subheading {
                childMarkdownRemark {
                  html
                }
              }
              iconWidth
              icon {
                gatsbyImageData(width: 32)
                title
                file {
                  contentType
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
            }
            ... on ContentfulComponentGuidesButton {
              internal {
                type
              }
              buttonText
              slug
            }
            ... on ContentfulComponentGuidesImage {
              internal {
                type
              }
              image {
                gatsbyImageData(placeholder:NONE)
                title
              }
              imageMaxWidth
            }
            ... on ContentfulComponentTable {
              internal {
                type
              }
              tableOptions
            }
            ... on ContentfulComponentGuideFaQs {
              internal {
                type
              }
              faQs {
                question
                answer {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      }
      seoMetaTitle
      seoMetaDescription
      seoMetaImage {
        fluid {
          src
        }
      }
    }
  }
`
