import React from 'react'
import SecuredvsUnsecuredLoans from './SecuredvsUnsecuredLoans'
import DebtConsolidationProsCons from './DebtConsolidationProsCons'
import PersonalDataInCreditReport from './PersonalDataInCreditReport'
import TaxRefund from './TaxRefund'
import TaxAccountantProsCons from './TaxAccountantProsCons'
import LoanDetailsTable from '../../ForPersonalLoans/PersonalLoanPages/TheRightLoanForYou/LoanDetailsTable'
import RatesCompare from '../HowItWorksAndCompare/RatesCompare'

/**
 * CMS now has a component called Component:Table where you choose an option of these tables listed below.
 */
const MapTable = ({ CMSTableOptions }) => {
  switch (CMSTableOptions) {
    case 'What is Secured and Unsecured Loan':
      return <SecuredvsUnsecuredLoans />
    case 'What is Secured and Unsecured Loan - 3 rows':
      return <SecuredvsUnsecuredLoans version={2} />
    case 'Debt Consolidation Pros and Cons':
      return <DebtConsolidationProsCons />
    case 'How Long My Personal Data Held In Credit Report':
      return <PersonalDataInCreditReport />
    case 'Tax Refund':
      return <TaxRefund />
    case 'Tax Accountant Pros and Cons':
      return <TaxAccountantProsCons />
    case 'Secured and Unsecured Loans Details':
      return <LoanDetailsTable />
    case 'Rates To Compare':
      return (
        <div style={{ maxWidth: 550, marginLeft: 'auto', marginRight: 'auto' }}>
          <RatesCompare />
        </div>
      )
    default:
      return null
  }
}

export default MapTable
