import React from 'react'
import styled from 'styled-components'

const SecuredvsUnsecuredLoans = ({ version = 1 }) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        <thead>
          <tr>
            <th>{""}</th>
            <th>Secured</th>
            <th>Unsecured</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="col-title">Asset Requirement</td>
            <td>Backed by an asset you own, such as your home or car</td>
            <td>Require no security to set up</td>
          </tr>
          {version === 2 && (
            <tr>
              <td className="col-title">Variances in Interest Rate</td>
              <td>Lower interest rates</td>
              <td>Higher interest rates</td>
            </tr>
          )}
          <tr>
            <td className="col-title">Restrictions on Loan Usage</td>
            <td>Some restrictions</td>
            <td>Little to no restrictions</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: left;
  font-size: 16px;
  th {
    font-family: QuicksandBold;
    background-color: rgba(210, 242, 226, 0.8);
  }
  th,
  td {
    width: 33%;
    padding: 13px 24px;
    border: 1px solid #d4dbe0;
  }
  .col-title {
    background-color: rgba(210, 242, 226, 0.2);
  }
  border: 1px solid #d4dbe0;
	td:nth-child(3n+1) {
		font-family: QuicksandBold;
	}
  @media screen and (max-width: 768px) {
    font-size: 13px;
    th,
    td {
      padding: 12px;
    }
  }
  @media screen and (max-width: 479px) {
		th, td {
      padding: 6px;
    }
	}
`

export default SecuredvsUnsecuredLoans
