import React, { useState } from 'react'
import styled from 'styled-components'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useStaticQuery, graphql } from 'gatsby'

import { ComparisonRate, InterestRate } from './Rates'

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabPanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<h3 style={{fontFamily: 'QuicksandBold'}}>{children}</h3>
			)}
		</div>
	);
}

const a11yProps = (index) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabPanel-${index}`,
	};
}

const RatesCompare = () => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const data = useStaticQuery(graphql`
		query RatesCompareDataQuery {
			contentfulComponentJsonTable(tableName: {eq: "RatesCompareData"}) {
				data {
					name
					comparisonRate
					interestRate
					color
					background
					star
					from
				}
			}
		}
	`)

	// to find the max from all the rates
	const rates = []

	data.contentfulComponentJsonTable.data.forEach((item) => {
		rates.push(item.comparisonRate, item.interestRate)
	})

	const maxRate = Math.max.apply(null, rates)
	

	
	// to find the each width for chart and add to the array
	const ratesArray = data.contentfulComponentJsonTable.data.map((item, index) => {
		return {
			...item, 
			comparisonRateWidth: ((parseInt(item.comparisonRate)*0.76/maxRate).toFixed(2) * 100).toString() + '%', // 0.76 is for the rate itself, the rest of 0.24 is for the text('from 14.6%	p.a.')
			interestRateWidth: ((parseInt(item.interestRate)*0.76/maxRate).toFixed(2) * 100).toString() + '%'
		}
	})

	return (
		<RatesCompareWrapper>
			<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered TabIndicatorProps={{style: {background:'rgb(38 75 101)'}}} style={{marginBottom: '20px'}}>
				<Tab label="Comparison Rate" {...a11yProps(0)} />
				<Tab label="Interest Rate" {...a11yProps(1)} />
			</Tabs>
			<TabPanel value={value} index={0}>
        <RateChart>
					{ratesArray.map((item, index) =>
						<ComparisonRate item={item} key={index}/>
					)}
				</RateChart>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<RateChart>
					{ratesArray.map((item, index) =>
						<InterestRate item={item} key={index}/>
					)}
				</RateChart>
      </TabPanel>
		</RatesCompareWrapper>
	)
}

const RatesCompareWrapper = styled.div`
	width: 100%;
	margin: 0px;
	padding: 0px;
`
const RateChart = styled.div`
	border: 1px solid rgb(215 215 215);
	border-radius: 16px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	padding: 32px;
	@media screen and (max-width: 480px) {
		padding: 10px 0px 10px 7px;
	}

`

export default RatesCompare
