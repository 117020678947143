import React from 'react'
import styled from 'styled-components'

export const ComparisonRate = (props) => {

	const { name, comparisonRate, comparisonRateWidth, color, background, star, from } = props.item

	return (
		<RateItem>
			<Bar 
				background={background}
				comparisonRateWidth={comparisonRateWidth}
				color={color}
			>
				{name}
			</Bar>
			<Text>
				{from === "true" && 'from'} {comparisonRate}% p.a.{star === "true" && '*'}
			</Text>
		</RateItem>
	)
}

export const InterestRate = (props) => {
	
	const { name, interestRate, interestRateWidth, color, background, star, from } = props.item

	return (
		<RateItem style={{ padding: '5px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
			<Bar 
				background={background}
				interestRateWidth={interestRateWidth}
				color={color}
			>
				{name}
			</Bar>
			<Text>
				{from === "true" && 'from'} {interestRate}% p.a.{star === "true" && '*'}
			</Text>
		</RateItem>
	)
}

const RateItem = styled.div`
	padding: 5px; 
	display: flex; 
	justify-content: flex-start; 
	align-items: center;
	@media screen and (max-width: 480px) {
		padding: 5px 0; 
	}

`
const Bar = styled.div`
	background: ${props => props.background}; 
	border-radius: 50px; 
	padding: 11px 22px; 
	font-size: 15px; 
	width: ${props => props.comparisonRateWidth || props.interestRateWidth}; 
	color: ${props => props.color};
	@media screen and (max-width: 480px) {
		padding: 11px 10px; 
		font-size: 13px;  
	}


`

const Text = styled.div`
	font-size: 16px; 
	padding-left: 20px; 
	@media screen and (max-width: 480px) {
		font-size: 12px; 
		padding-left: 10px; 
	}

`