import React from 'react'
import styled from 'styled-components'

import InlineIcon from '../InlineIcon'

const SecuredvsUnsecuredLoans = () => {
  return (
    <TableWrapper className="grid">
      <div className="th left">Advantages</div>
      <div className="th disadvantages">Disadvantages</div>
      <div className="info left">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">
            Gain back control of your credit card
          </div>
        </InlineIcon>
        <p>
          Moving your credit card balance to a lower interest rate personal loan
          can save you money, and offer a fixed payment schedule that makes it
          even easier to budget.
        </p>
      </div>
      <div className="info exit-fees">
        <InlineIcon iconName="X" mb="10px">
          <div className="info-title">Exit fees</div>
        </InlineIcon>
        <p>
          You may be charged an early exit fee from your current loans depending
          on your loan provider and loan terms. SocietyOne does not charge any
          exit fees or early repayment fees meaning you can pay off your loan
          early without any penalty.
        </p>
      </div>
      <div className="info left">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">Fold multiple debts into one loan</div>
        </InlineIcon>
        <p>
          Save on fees with one consolidated loan and have one regular repayment
          date.
        </p>
      </div>
      <div className="info taxes-may-apply">
        <InlineIcon iconName="X" mb="10px">
          <div className="info-title">Taxes may apply</div>
        </InlineIcon>
        <p>
          Government duties and taxes may apply if you are using your home loan
          to consolidate your debts.
        </p>
      </div>
      <div className="info left">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">Keep on top of payments</div>
        </InlineIcon>
        <p>
          Having a single repayment date makes it less likely that you’ll miss a
          repayment. Overdue payments typically lead to a default, resulting in
          your credit score decreasing along with additional fees.
        </p>
      </div>
      <div className="info hide-mobile"></div>
      <div className="info left">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">Budget more easily</div>
        </InlineIcon>
        <p>
          A single payment makes it easier to budget and making life simpler.
          You’ll spend less time doing maths and more time putting careful
          consideration into your monthly budgets.
        </p>
      </div>
      <div className="info hide-mobile"></div>
      <div className="info left">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">Stop nuisance calls from creditors</div>
        </InlineIcon>
        <p>
          Are you worried every time the phone rings? Consolidating your debts
          will stop debt collection agencies harassing you.
        </p>
      </div>
      <div className="info hide-mobile"></div>
      <div className="info left last">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">
            Avoid bankruptcy or a bad credit rating
          </div>
        </InlineIcon>
        <p>
          You may be able to avoid bankruptcy and avoid defaulting on your
          current debt by consolidating your debts into one personal loan,
          stopping your debt from spiralling further out of control.
        </p>
      </div>
      <div className="info hide-mobile last"></div>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  p {
    margin: 0;
  }
  .th {
    padding: 13px 24px;
    border-top: 1px solid #d4dbe0;
    border-right: 1px solid #d4dbe0;
    border-left: 1px solid #d4dbe0;
    background-color: rgba(210, 242, 226, 0.8);
    font-size: 18px;
    font-family: QuicksandBold;
  }
  .info {
    padding: 16px 24px;
    border-top: 1px solid #d4dbe0;
    border-right: 1px solid #d4dbe0;
    border-left: 1px solid #d4dbe0;
    border-width: 1px;
    border-color: #d4dbe0;
    font-size: 18px;
  }
  .info-title {
    margin-top: 0;
    margin-bottom: 0;
    font-family: PoppinsBold;
    color: #264b65;
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: 0;
  }
  .left {
    border-right: 0;
  }
  .last {
    border-bottom: 1px solid #d4dbe0;
  }

  .icon-check {
    margin-right: 5px;
  }

  @media screen and (max-width: 767px) {
    .info {
      padding: 12px 20px;
      font-size: 16px;
    }
    .info-title {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 479px) {
    grid-template-columns: 1fr;
    .th {
      font-size: 14px;
    }
    .disadvantages {
      margin-top: 24px;
      grid-row: 8 / 9;
      grid-column: 1 / 2;
    }
    .exit-fees {
      grid-row: 9 / 10;
      grid-column: 1 / 2;
    }
    .taxes-may-apply {
      grid-row: 10 / 11;
      grid-column: 1 / 2;
      border-bottom: 1px solid #d4dbe0;
    }
    .info {
      padding-right: 12px;
      padding-left: 12px;
      p {
        font-size: 15px;
      }
    }
    .left {
      border-right: 1px solid #d4dbe0;
    }
    .hide-mobile {
      display: none;
    }
  }
`

export default SecuredvsUnsecuredLoans
