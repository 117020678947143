import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const PersonalDataInCreditReport = () => {
  const data = useStaticQuery(graphql`
  query PersonalDataInCreditReport {
    contentfulComponentJsonTable(tableName: {eq: "PersonalDataInCreditReport"}) {
      data {
        td1 {
          content
        }
        td2 {
          content
        }
        td3 {
          content
        }
      }
    }
  }
`)

const items = data.contentfulComponentJsonTable.data

  return (
    <Table>
      <thead>
        <tr>
          <th>Personal Information</th>
          <th>Type of Information Held</th>
          <th>Number of Years Held</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) => 
          <tr key={i}>
            <td>{item.td1.content}</td>
            <td>{item.td2.content}</td>
            <td className="text-center">{item.td3.content}</td>
          </tr>
        )}       
      </tbody>
    </Table>
  )
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  text-align: left;
  font-size: 18px;
  th {
    font-family: QuicksandBold;
    background-color: rgba(218, 220, 246, 0.7);
  }
  th,
  td {
    width: 33%;
    padding: 13px 24px;
    border: 1px solid #d4dbe0;
  }
  border: 1px solid #d4dbe0;
  @media screen and (max-width: 768px) {
    font-size: 13px;
    th,
    td {
      padding: 12px;
    }
  }
`

export default PersonalDataInCreditReport
