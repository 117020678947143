import React from 'react'
import styled from 'styled-components'

import InlineIcon from '../InlineIcon'

const SecuredvsUnsecuredLoans = () => {
  return (
    <TableWrapper className="grid">
      <div className="th left">Advantages</div>
      <div className="th disadvantages">Disadvantages</div>
      <div className="info left">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">Their fee is tax-deductible</div>
        </InlineIcon>
        <p>
          Using a tax agent is completely tax-deductible, so you generally don’t
          need to worry about it costing you a lot.
        </p>
      </div>
      <div className="info exit-fees">
        <InlineIcon iconName="X" mb="10px">
          <div className="info-title">
            Tax returns can be pretty straightforward
          </div>
        </InlineIcon>
        <p>
          If you only have one income, few items to claim and good bookkeeping,
          lodging a tax return can be fairly quick and easy.
        </p>
      </div>
      <div className="info left">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">Claim more deductions</div>
        </InlineIcon>
        <p>
          Tax agents are professionals; they know exactly what you can and can’t
          claim for so they’ll be able to maximise the amount you’re entitled
          to.
        </p>
      </div>
      <div className="info taxes-may-apply">
        <InlineIcon iconName="X" mb="10px">
          <div className="info-title">
            The ATO has important information already
          </div>
        </InlineIcon>
        <p>
          The ATO has a lot of data it pre-fills into your return so some of the
          work is done for you and reduces your chances of making a mistake.
        </p>
      </div>
      <div className="info left">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">Correct claims</div>
        </InlineIcon>
        <p>
          If you make a mistake and don’t claim your deductions correctly, the
          ATO won’t hesitate to fine you. Tax agents can provide peace of mind,
          so you don’t need to sweat the little stuff come tax time.
        </p>
      </div>
      <div className="info ato-has-info">
        <InlineIcon iconName="X" mb="10px">
          <div className="info-title">
            The ATO has important information already
          </div>
        </InlineIcon>
        <p>
          The ATO has a lot of data it pre-fills into your return so some of the
          work is done for you and reduces your chances of making a mistake.
        </p>
      </div>

      <div className="info left last">
        <InlineIcon iconName="Check" mb="10px">
          <div className="info-title">Time-consuming</div>
        </InlineIcon>
        <p>
          Life’s busy and many of us simply don’t want to spend our free time
          calculating things like home office expenses. If you do it yourself,
          you’ll need to keep track of this information throughout the year.
          With a tax agent, the work is done for you.
        </p>
      </div>
      <div className="info hide-mobile last"></div>
    </TableWrapper>
  )
}

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  p {
    margin: 0;
  }
  .th {
    padding: 13px 24px;
    border-top: 1px solid #d4dbe0;
    border-right: 1px solid #d4dbe0;
    border-left: 1px solid #d4dbe0;
    background-color: rgba(210, 242, 226, 0.8);
    font-size: 18px;
    font-family: QuicksandBold;
  }
  .info {
    padding: 16px 24px;
    border-top: 1px solid #d4dbe0;
    border-right: 1px solid #d4dbe0;
    border-left: 1px solid #d4dbe0;
    border-width: 1px;
    border-color: #d4dbe0;
    font-size: 18px;
  }
  .info-title {
    margin-top: 0;
    margin-bottom: 0;
    font-family: PoppinsBold;
    color: #264b65;
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: 0;
  }
  .left {
    border-right: 0;
  }
  .last {
    border-bottom: 1px solid #d4dbe0;
  }

  .icon-check {
    margin-right: 5px;
  }

  @media screen and (max-width: 767px) {
    .info {
      padding: 12px 20px;
      font-size: 16px;
    }
    .info-title {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 479px) {
    grid-template-columns: 1fr;
    .th {
      font-size: 14px;
    }
    .disadvantages {
      margin-top: 24px;
      grid-row: 8 / 9;
      grid-column: 1 / 2;
    }
    .exit-fees {
      grid-row: 9 / 10;
      grid-column: 1 / 2;
    }
    .taxes-may-apply {
      grid-row: 10 / 11;
      grid-column: 1 / 2;
    }
    .ato-has-info {
      grid-row: 11 / 12;
      grid-column: 1 / 2;
      border-bottom: 1px solid #d4dbe0;
    }
    .info {
      padding-right: 12px;
      padding-left: 12px;
      p {
        font-size: 15px;
      }
    }
    .left {
      border-right: 1px solid #d4dbe0;
    }
    .hide-mobile {
      display: none;
    }
  }
`

export default SecuredvsUnsecuredLoans
