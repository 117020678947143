import React from 'react'
import styled from 'styled-components'

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CMSImage from './CMSImage'

const BulletListItem = ({ item }) => {
  return (
    <ItemWrapper>
      {item.expand ? (
        <Accordion
          sx={{ border: 0, boxShadow: 'none', '&:before': { display: 'none' }, h3: {margin: 0} }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            sx={{
              padding: 0,
              justifyContent: 'flex-start',
              '& .MuiAccordionSummary-content': { flexGrow: 0 },
              '& .MuiSvgIcon-root': { width: 30 },
            }}
          >
            <div className="flex-row" style={{ alignItems: 'center' }}>
              {item.icon && (
                <div className="icon" style={{ width: item.iconWidth || 30 }}>
                  <CMSImage
                    svg={item.icon?.svg}
                    gatsbyImageData={item.icon?.gatsbyImageData}
                    file={item.icon?.file}
                    alt={item.icon?.title}
                  />
                </div>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: item.heading.childMarkdownRemark.html,
                }}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0', margin: '0' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.subheading?.childMarkdownRemark.html,
              }}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <div
          className="flex-row"
          style={{ alignItems: item.subheading ? 'start' : 'center' }}
        >
          {item.icon && (
            <div className="icon" style={{ width: item.iconWidth || 30 }}>
              <CMSImage
                svg={item.icon?.svg}
                gatsbyImageData={item.icon?.gatsbyImageData}
                file={item.icon?.file}
                alt={item.icon?.title}
              />
            </div>
          )}
          <div className="flex-col">
            <div
              dangerouslySetInnerHTML={{
                __html: item.heading.childMarkdownRemark.html,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: item.subheading?.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      )}
    </ItemWrapper>
  )
}

const ItemWrapper = styled.div`
  margin-bottom: 20px;
  word-wrap: break-word;
  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }
  strong {
    margin: 0;
  }
  p {
    margin: 5px;
  }
  .icon {
    margin-right: 10px;
    flex-shrink: 0;
  }
  img {
    width: 100%;
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

export default BulletListItem
